import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Mui, MuiIcons, MuiStyles } from '@osu/react-ui';
import AuthButton from '../containers/AuthButton';
import { BlackIconButton } from '../../Common/components/Buttons';
import { ACTION_STATUS } from '../../util/constants';

function NavMenu(props = {}) {
  return (
    <Mui.Menu elevation={12} anchorEl={null} sx={{ paper: { border: '1px solid #d3d4d5' } }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      {...props}
    />
  );
}

export default function Navbar(props = {}) {
  const { authStatus, user } = props;
  const isAuthenticated = (authStatus === ACTION_STATUS.SUCCESS);
  const { isContactApprover = false, isEnrollmentAdmin = false, isFaculty = false, isFacultyApprover = false,
    isGradPlanAdmin = false, isGraduationAdmin = false, isInquirer = false, isStudent = false } = (user ?? {});
  
  const [navMenuEl, setNavMenuEl] = useState(null);
  const [formsNavMenuEl, setFormsNavMenuEl] = useState(null);
  const [adminNavMenuEl, setAdminNavMenuEl] = useState(null);
  const [searchNavMenuEl, setSearchNavMenuEl] = useState(null);
  const [reportsNavMenuEl, setReportsNavMenuEl] = useState(null);
  const theme = MuiStyles.useTheme();
  
  const isNavMenuOpen = (el) => {
    return Boolean(el);
  };
  const getNavMenuIcon = (el) => {
    return (isNavMenuOpen(el) ? <MuiIcons.KeyboardArrowUpRounded /> : <MuiIcons.KeyboardArrowDownRounded />);
  };
  const closeNavMenus = () => {
    setNavMenuEl(null);
    setFormsNavMenuEl(null);
    setSearchNavMenuEl(null);
    setAdminNavMenuEl(null);
    setReportsNavMenuEl(null);
  };

  // close navigation menus on window resize
  useEffect(() => {
    window.addEventListener("resize", closeNavMenus);
    return () => {
      window.removeEventListener("resize", closeNavMenus);
    };
  });

  const homeNavLink = { name: "Home", path: "/" };
  const formsNavLinks = [
    /* { name: "Enrollment", path: "/forms/enrollment", render: isStudent }, */
    { name: "Graduate Faculty", path: "/form/graduate-faculty-nomination", render: isGradPlanAdmin },
    { name: "Graduation/Examination", path: "/forms/graduation-and-examination", render: isStudent }/* ,
    { name: "Specialization", path: "/form/specialization", render: isGradPlanAdmin }, 
    { name: "Reports", path: "/forms/reports", render: isStudent } */
  ];
  const renderFormsNav = (formsNavLinks.filter(link => (link.render === true)).length > 0);
  const adminNavLinks = [
    { name: "Contact Maintenance", path: "/admin/contact-maintenance", render: (isContactApprover || isGradPlanAdmin) },
    { name: "Enrollment Tasks", path: "/enrollment-tasks", render: isEnrollmentAdmin },
    { name: "Faculty Maintenance", path: "/admin/faculty-maintenance", render: (isFacultyApprover || isGradPlanAdmin) },
    { name: "Graduation/Examination Tasks", path: "/graduation-examination-tasks", render: isGraduationAdmin },
    { name: "Pending List", path: "/admin/pending-maintenance", render: (isContactApprover || isFacultyApprover) },
    { name: "Tasks", path: "/tasks", render: (isGradPlanAdmin || isFacultyApprover) }
  ];
  const renderAdminNav = (adminNavLinks.filter(link => (link.render === true)).length > 0);
  const searchNavLinks = [
    { name: "Contact", path: "/search/contact", render: true },
    { name: "Faculty", path: "/search/faculty", render: !isStudent }, 
    { name: "Forms", path: "/search/forms", render: true },
    { name: "Graduation Records", path: "/search/graduationrecords", render: true }
  ];
  const renderSearchNav = false/* (!isStudent) */;
  const reportsNavLinks = [
    { name: "Graduation Dashboard ", path: "/", render: true } // TODO: Set path when available
  ];
  const renderReportsNav = false/* (isGradPlanAdmin || isGraduationAdmin || isInquirer || isStudent) */;

  const smUp = Mui.useMediaQuery(theme => theme.breakpoints.up("sm"));
  const smDown = Mui.useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <Mui.AppBar id="app-header" color="inherit" sx={{ top: '0', zIndex: theme.zIndex.appBar }} position="sticky">
      <Mui.Toolbar>
        <Mui.Box sx={{ flexGrow: 1 }}>
          {isAuthenticated &&
            <Fragment>
              {smUp &&
                <Mui.Box>
                  {/* Navigation menu for small screen sizes and larger */}
                  <Mui.Button component={Link} to={homeNavLink.path} color="secondary">
                    {homeNavLink.name}
                  </Mui.Button>
                  {renderFormsNav &&
                    <Fragment>
                      {/* Forms menu items */}
                      <Mui.Button aria-controls="forms-nav-menu" aria-expanded={isNavMenuOpen(formsNavMenuEl)} aria-haspopup="true" color="secondary" endIcon={getNavMenuIcon(formsNavMenuEl)} 
                        onClick={(e) => setFormsNavMenuEl(isNavMenuOpen(formsNavMenuEl) ? null : e.currentTarget)}>
                        Forms
                      </Mui.Button>
                      <NavMenu id="forms-nav-menu" anchorEl={formsNavMenuEl} keepMounted open={isNavMenuOpen(formsNavMenuEl)} onClose={() => setFormsNavMenuEl(null)}>
                        {formsNavLinks.map((navLink, index) => {
                          return (
                          navLink.render === true ? 
                          (<Mui.MenuItem key={index} component={Link} to={navLink.path} onClick={() => setFormsNavMenuEl(null)}>{navLink.name}</Mui.MenuItem>) :
                          null
                          );
                        })}
                      </NavMenu>
                    </Fragment>
                  }
                  {renderAdminNav &&
                    <Fragment>
                      {/* Admin menu items */}
                      <Mui.Button aria-controls="admin-nav-menu" aria-expanded={isNavMenuOpen(adminNavMenuEl)} aria-haspopup="true" color="secondary" endIcon={getNavMenuIcon(adminNavMenuEl)} 
                        onClick={(e) => setAdminNavMenuEl(isNavMenuOpen(adminNavMenuEl) ? null : e.currentTarget)}>
                        Admin
                      </Mui.Button>
                      <NavMenu id="admin-nav-menu" anchorEl={adminNavMenuEl} keepMounted open={isNavMenuOpen(adminNavMenuEl)} onClose={() => setAdminNavMenuEl(null)}>
                        {adminNavLinks.map((navLink, index) => {
                            return (
                            navLink.render === true ? 
                            (<Mui.MenuItem key={index} component={Link} to={navLink.path} onClick={() => setAdminNavMenuEl(null)}>{navLink.name}</Mui.MenuItem>) :
                            null
                            );
                        })}
                      </NavMenu>
                    </Fragment>
                  }
                  {renderSearchNav &&
                    <Fragment>
                      {/* Search menu items */}
                      <Mui.Button aria-controls="search-nav-menu" aria-expanded={isNavMenuOpen(searchNavMenuEl)} aria-haspopup="true" color="secondary" endIcon={getNavMenuIcon(searchNavMenuEl)} 
                        onClick={(e) => setSearchNavMenuEl(isNavMenuOpen(searchNavMenuEl) ? null : e.currentTarget)}>
                        Search
                      </Mui.Button>
                      <NavMenu id="search-nav-menu" anchorEl={searchNavMenuEl} keepMounted open={isNavMenuOpen(searchNavMenuEl)} onClose={() => setSearchNavMenuEl(null)}>
                        {searchNavLinks.map((navLink, index) => {
                            return (<Mui.MenuItem key={index} component={Link} to={navLink.path} onClick={() => setSearchNavMenuEl(null)}>{navLink.name}</Mui.MenuItem>);
                        })}
                      </NavMenu>
                    </Fragment>
                  }
                  {renderReportsNav &&
                    <Fragment>
                      {/* Reports menu items */}
                      <Mui.Button aria-controls="reports-nav-menu" aria-expanded={isNavMenuOpen(reportsNavMenuEl)} aria-haspopup="true" color="secondary" endIcon={getNavMenuIcon(reportsNavMenuEl)} 
                        onClick={(e) => setReportsNavMenuEl(isNavMenuOpen(reportsNavMenuEl) ? null : e.currentTarget)}>
                        Reports
                      </Mui.Button>
                      <NavMenu id="reports-nav-menu" anchorEl={reportsNavMenuEl} keepMounted open={isNavMenuOpen(reportsNavMenuEl)} onClose={() => setReportsNavMenuEl(null)}>
                        {reportsNavLinks.map((navLink, index) => {
                            return (<Mui.MenuItem key={index} component={Link} to={navLink.path} onClick={() => setReportsNavMenuEl(null)}>{navLink.name}</Mui.MenuItem>);
                        })}
                      </NavMenu>
                    </Fragment>
                  }
                </Mui.Box>
              }
              {smDown &&
                <Mui.Box>
                  {/* Navigation menu for extra small screen size */}
                  <Mui.IconButton edge="start" color="inherit" aria-label="Navigation Menu" aria-controls="nav-menu" aria-expanded={isNavMenuOpen(navMenuEl)} aria-haspopup="true" 
                    onClick={(e) => setNavMenuEl(isNavMenuOpen(navMenuEl) ? null : e.currentTarget)}>
                    <MuiIcons.Menu />
                  </Mui.IconButton>
                  <NavMenu id="nav-menu" anchorEl={navMenuEl} open={isNavMenuOpen(navMenuEl)} onClose={() => closeNavMenus()}>
                    <Mui.MenuItem component={Link} to={homeNavLink.path} onClick={() => closeNavMenus()}>
                      {homeNavLink.name}
                    </Mui.MenuItem>
                    {renderFormsNav &&
                      <Fragment>
                        {/* Forms menu items */}
                        <Mui.ListItem button aria-controls="forms-nav-menu" aria-expanded={isNavMenuOpen(formsNavMenuEl)}
                          onClick={(e) => setFormsNavMenuEl(isNavMenuOpen(formsNavMenuEl) ? null : e.currentTarget)}>
                          <Mui.ListItemText primary="Forms" />
                          {getNavMenuIcon(formsNavMenuEl)}
                        </Mui.ListItem>
                        <Mui.Collapse id="forms-nav-menu" in={isNavMenuOpen(formsNavMenuEl)} timeout="auto" unmountOnExit>
                          <Mui.List component="div" disablePadding>
                            {formsNavLinks.map((navLink, index) => {
                              return (
                                navLink.render === true ? 
                                (<Mui.MenuItem key={index} component={Link} to={navLink.path} onClick={() => closeNavMenus()}>{navLink.name}</Mui.MenuItem>) :
                                null
                              );
                            })}
                          </Mui.List>
                        </Mui.Collapse>
                      </Fragment>
                    }
                    {renderAdminNav &&
                      <Fragment>
                        {/* Admin menu items */}
                        <Mui.ListItem button aria-controls="admin-nav-menu" aria-expanded={isNavMenuOpen(adminNavMenuEl)}
                          onClick={(e) => setAdminNavMenuEl(isNavMenuOpen(adminNavMenuEl) ? null : e.currentTarget)}>
                          <Mui.ListItemText primary="Admin" />
                          {getNavMenuIcon(adminNavMenuEl)}
                        </Mui.ListItem>
                        <Mui.Collapse id="admin-nav-menu" in={isNavMenuOpen(adminNavMenuEl)} timeout="auto" unmountOnExit>
                          <Mui.List component="div" disablePadding>
                            {adminNavLinks.map((navLink, index) => {
                              return (
                                navLink.render === true ? 
                                (<Mui.MenuItem key={index} component={Link} to={navLink.path} onClick={() => closeNavMenus()}>{navLink.name}</Mui.MenuItem>) :
                                null
                              );
                            })}
                          </Mui.List>
                        </Mui.Collapse>
                      </Fragment>
                    }
                    {renderSearchNav &&
                      <Fragment>
                        {/* Search menu items */}
                        <Mui.ListItem button aria-controls="search-nav-menu" aria-expanded={isNavMenuOpen(searchNavMenuEl)}
                          onClick={(e) => setSearchNavMenuEl(isNavMenuOpen(searchNavMenuEl) ? null : e.currentTarget)}>
                          <Mui.ListItemText primary="Search" />
                          {getNavMenuIcon(searchNavMenuEl)}
                        </Mui.ListItem>
                        <Mui.Collapse id="search-nav-menu" in={isNavMenuOpen(searchNavMenuEl)} timeout="auto" unmountOnExit>
                          <Mui.List component="div" disablePadding>
                            {searchNavLinks.map((navLink, index) => {
                              return (<Mui.MenuItem key={index} component={Link} to={navLink.path} onClick={() => closeNavMenus()}>{navLink.name}</Mui.MenuItem>);
                            })}
                          </Mui.List>
                        </Mui.Collapse>
                      </Fragment>
                    }
                    {renderReportsNav &&
                      <Fragment>
                        {/* Reports menu items */}
                        <Mui.ListItem button aria-controls="reports-nav-menu" aria-expanded={isNavMenuOpen(reportsNavMenuEl)}
                          onClick={(e) => setReportsNavMenuEl(isNavMenuOpen(reportsNavMenuEl) ? null : e.currentTarget)}>
                          <Mui.ListItemText primary="Reports" />
                          {getNavMenuIcon(reportsNavMenuEl)}
                        </Mui.ListItem>
                        <Mui.Collapse id="reports-nav-menu" in={isNavMenuOpen(reportsNavMenuEl)} timeout="auto" unmountOnExit>
                          <Mui.List component="div" disablePadding>
                            {reportsNavLinks.map((navLink, index) => {
                              return (<Mui.MenuItem key={index} component={Link} to={navLink.path} onClick={() => closeNavMenus()}>{navLink.name}</Mui.MenuItem>);
                            })}
                          </Mui.List>
                        </Mui.Collapse>
                      </Fragment>
                    }
                  </NavMenu>
                </Mui.Box>
              }
            </Fragment>
          }
        </Mui.Box>
        {isAuthenticated &&
          <BlackIconButton aria-label="Forms Help" href="https://gradsch.osu.edu/forms-help" target="_blank" rel="noopener">
            <MuiIcons.Help />
          </BlackIconButton>
        }
        <AuthButton />
      </Mui.Toolbar>
    </Mui.AppBar>
  );
}