import { sortBy } from "lodash";
import { APPOINTMENT } from "../util/constants";

const formatDropdownSubtypeFromState = (values) => {
  const { subtype, dropdowns: dropdownOptions } = values
  let optionState = {
    options: [],
    loading: false,
  };

  const relevantFields = dropdownOptions?.[subtype]
  if(relevantFields) {
    optionState = {
      options: Array.isArray(relevantFields.options)
      ? relevantFields.options
      : [],
      subtype,
      queries: relevantFields?.queries,
      status: relevantFields?.status,
      loading: relevantFields?.status === "loading",
    }
  }

  return optionState;
};

export const constructFacultyFields = (faculty = {}) => {
  const { emplid, program, category } = faculty
  
  const identifier = [emplid, program, category].filter(item => !!item);
  if(identifier?.length) {
    let fields = { id: "", title: "" };
    const categoryDisplay = APPOINTMENT.CATEGORY[category];
    const facultyName = buildFacultyName(faculty);
    
    fields.id = identifier.join("-");
    fields.title = (facultyName || "");
    if(fields.title && program) fields.title += ` - ${program}`;
    if(fields.title && categoryDisplay) fields.title += ` - Category ${categoryDisplay}`;

    return fields;
  }

  return {}
}

export const buildFacultyName = (faculty) => {
  let name = ''
  if(faculty?.firstName) name += faculty.firstName
  if(faculty?.middleInitial) name += ` ${faculty.middleInitial}`
  if(faculty?.lastName) name += ` ${faculty.lastName}`
  return name
};
const transformGraduateFacultyDropdownOptions = (graduateFaculty = [], skipGrouping = false) => {
  if(skipGrouping) {
    return graduateFaculty
  }
  const options = [];
  for(const faculty of graduateFaculty) {
    for(const appointment of (faculty.appointments ?? [])) {
      const category = appointment.category;
      const categoryDisplay = APPOINTMENT.CATEGORY[category];
      const program = appointment.program;
      if(program && categoryDisplay) {
        const value = { 
          emplid: faculty.emplid,
          firstName: faculty.firstName,
          lastName: faculty.lastName,
          middleName: faculty.middleInitial,
          facultyEmail: faculty.email,
          nameN: faculty.namedotn,
          phone: faculty.phone,
          program,
          category
        }; // matches PersonInput in schema
        const labels = constructFacultyFields(value)
        options.push({
          id: labels?.id,
          title: labels?.title,
          value,
          gradPlan: program // used for filtering
        });
      }
    }
  }
  return sortBy(options, ["title"]);
}

export { formatDropdownSubtypeFromState, transformGraduateFacultyDropdownOptions };
