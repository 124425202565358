import { dropdownSubtypes, inputMappings } from "../../util/enums";

const SK_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  DUAL: 'DUAL',
}

const createSharedCourseQuestions = (type, skType) => [
  {
    id: `courses-ig-${type}-title`,
    type: inputMappings.textInput,
    title: "Course Title",
    dataField: "courseTitle",
    summary: null,
    required: true,
    skType
  },
  {
    id: `courses-ig-${type}-number`,
    type: inputMappings.textInput,
    title: "Course Number",
    dataField: "courseNumber",
    summary: null,
    required: true,
    skType
  },
  {
    id: `courses-ig-${type}-credit-hours`,
    type: inputMappings.textInput,
    title: "Credit Hours",
    dataField: "creditHours",
    summary: null,
    required: true,
    skType
  },
]
const data = [
  {
    id: "primary-info",
    questions: [
      {
        title: "Begin Term of Dual Program",
        id: "begin-term",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        dataField: "termCode",
        summary: "test",
        filter: {
          showFromPresent: 5
        }
      },
    ],
  },
  {
    title: "Primary Program",
    id: "primary-program",
    questions: [
      {
        title: "Current Graduation Level",
        id: "current-graduation-level",
        dataField: "currentGradLevel",
        required: true,
        type: inputMappings.dropDown,
        summary:
          "If you are uncertain of your academic level, please contact your graduate program coordinator.",
        subtype: dropdownSubtypes.graduationLevel,
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduatePrograms,
        dataField: "gradPlan",
        filter: [
          {
            field: "current-graduation-level",
            match: "gradLevel",
          },
        ],
        branch: "advisor-info",
      },
      {
        id: "advisor-info",
        type: inputMappings.dropDown,
        title: "Advisor",
        dataField: "advisor",
        subtype: dropdownSubtypes.advisor,
        dependencies: ["graduation-information-plan"],
        dependencyHelperText:
          "Please select a plan to select the appropriate advisor.",
        required: true,
      },
      {
        id: "courses-input-group",
        title:
          "Primary Program Courses (may include previously completed courses)",
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        dataField: `courses#${SK_TYPE.PRIMARY}`,
        questions: createSharedCourseQuestions("primary", SK_TYPE.PRIMARY)
      },
    ],
  },
  {
    title: "Secondary Program",
    id: "secondary-program",
    questions: [
      {
        title: "Proposed Academic Level",
        id: "proposed-academic-level",
        required: true,
        type: inputMappings.dropDown,
        dataField: "proposedAcadLevel",
        subtype: dropdownSubtypes.graduationLevel,
      },
      {
        title: "Proposed Academic Plan",
        id: "proposed-academic-plan",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduatePrograms,
        dataField: "proposedAcadPlan",
        filter: [
          {
            field: "proposed-academic-level",
            match: "gradLevel",
          },
        ],
        branch: "proposed-advisor-info",
      },
      {
        id: "proposed-advisor-info",
        type: inputMappings.dropDown,
        title: "Proposed Advisor",
        dataField: "proposedAdvisor",
        subtype: dropdownSubtypes.proposedAdvisor,
        dependencies: ["proposed-academic-plan"],
        dependencyHelperText:
          "Please select a plan to select the appropriate advisor.",
        required: true,
      },
      {
        id: "secondary-courses-input-group",
        title:
          "Secondary Program Courses (may include previously completed courses)",
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        dataField: `courses#${SK_TYPE.SECONDARY}`,
        questions: createSharedCourseQuestions("secondary", SK_TYPE.SECONDARY)
      },
    ],
  },
  {
    id: "dual",
    title: "Dual Credit Courses",
    questions: [
      {
        id: "dual-credit-courses-input-group",
        title: "Dual Credit Courses (may include previously completed courses)",
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        dataField: `courses#${SK_TYPE.DUAL}`,
        questions: createSharedCourseQuestions("dual-credit", SK_TYPE.DUAL)
      },
    ],
  },
];

export default data;
