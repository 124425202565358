import { dropdownSubtypes, inputMappings } from "../../util/enums";

const data = [
  {
    id: "form",
    questions: [
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        required: true,
        type: inputMappings.dropDown,
        summary:
          "If you are uncertain of your academic level, please contact your graduate program coordinator.",
        subtype: dropdownSubtypes.graduationLevel,
        branch: "graduation-information-plan",
        dataField: "gradLevel"
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduatePrograms,
        filter: [
          {
            field: "graduation-information-level",
            match: "gradLevel",
          },
        ],

        dataField: "gradPlan",
        results: [
          {
            label: "Graduation Degree",
            field: "degree",
            dataField: "gradDegree"
          },
        ],
        dependencyHelperText:
          "Please select a level to select the appropriate plan.",
        dependencies: ["graduation-information-level"],
      },
      {
        title: "Credit Type",
        id: "credit-type",
        dataField: "creditType",
        type: inputMappings.dropDown,
        summary:
          "Select Graduate Non-Degree if requesting OSU non-degree credit (up to 10 credit hours) be applied to your current program. Select Outside Institution if requesting external (non-OSU) credits be applied to your current program. Please refer to the Graduate School Handbook for limitations.",
        subtype: dropdownSubtypes.creditType,
        required: true
      },
      {
        id: "transfer-courses",
        title: "Transfer Courses",
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        dataField: "courses",
        questions: [
          {
            id: "courses-ig-institution",
            type: "textInput",
            title: "Institution",
            dataField: "institution",
            summary: null,
            required: true,
            gridProps: {
              xs: 6,
              lg: 4,
              md: 6
            },
          },
          {
            id: "courses-ig-title",
            type: "textInput",
            title: "Course Title",
            dataField: "courseTitle",
            summary: null,
            required: true,
            gridProps: {
              xs: 6,
              md: 6,
              lg: 4,
            },
          },
          {
            id: "courses-ig-number",
            type: "textInput",
            title: "Course Number",
            dataField: "courseNumber",
            summary: null,
            required: true,
            gridProps: {
              xs: 6,
              md: 4,
              lg: 2,
            },
          },
          {
            id: "courses-ig-credit-hours",
            type: "textInput",
            title: "Credit Hours",
            dataField: "creditHours",
            summary: null,
            required: true,
            gridProps: {
              xs: 6,
              md: 4,
              lg: 2,
            },
          },
        ],
      },
      {
        id: "advisor",
        type: inputMappings.dropDown,
        title: "Advisor",
        subtype: dropdownSubtypes.advisor,
        summary: null,
        required: true,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 1,
        dataField: "advisor",
        dependencies: ["graduation-information-plan"],
        dependencyHelperText:
          "Please select a plan to select the appropriate advisor.",
        choices: [],
      },
    ],
  },
];

export const additionalFilters = [ {
  id: "credit-type",
  title: "Credit Type",
  required: false,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.creditType
}];

export default data;
