import { Fragment } from "react";
import { dropdownSubtypes, loggedinUserData, inputMappings } from "../../util/enums";
import { createCommitteeSection } from "./util/shared";

const data = [
  {
    title: "Graduation Information",
    id: "graduation-information",
    questions: [
      {
        title: "Graduation Term",
        id: "graduation-information-term",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        dataField: 'termCode',
        filter: {
          showFromPresent: 2
        }
      },
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduationLevel, 
        branch: "graduation-information-plan",
        dataField: 'gradLevel',
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduatePrograms,
        filter: [
          {
            "field":  "graduation-information-level",
            "match": "gradLevel"
          }
        ],
        branch: "committee-advisor",
        results: [{
          label: 'Degree',
          field: "degree",
          dataField: 'gradDegree'
        }],
        dependencyHelperText: "Please select a level to select the appropriate plan.",
        dependencies: ["graduation-information-level"],
        dataField: 'gradPlan'
      },
      {
        //Summary to always show, clean up text at some point
        title: "End of Semester Option",
        id: "end-of-semester",
        summary:
          "The end of semester option means that you will not be graduating in the current semester. The EOS option is to be used only if all degree requirements are to be completed by the posted end of semester deadline with the degree being conferred at the commencement ceremony of the following semester. Do not utilize this option if you are trying to graduate using the regular semester/summer term graduation deadlines.",
        type: "checkbox",
        dataField: 'endOfSemesterOption',
        defaultValue: 'N',
        required: true
      },
    ],
  },
  {
    "id": "commencement",
    title: "Commencement",
    questions: [
      {
        id: "commencement-pick-up",
        type: inputMappings.radio,
        title: "How will you be obtaining your diploma?",
        required: true,
        dataField: 'commencement',
        choices: [
          {
            id: "commencement-will-attend",
            title: "I will attend commencement",
            value: "ATTEND"
          },
          {
            id: "commencement-one-week",
            title:
              "I will pick up my diploma within one week after commencement.",
            value: "PICKUP"
          },
          {
            id: "commencement-authorized-pickup",
            title: "I am authorizing someone else to pick up my diploma ",
            value: "AUTHORIZED_PICKUP",
            branch: "commencement-authorized-pickup-persons",
          },
          {
            id: "commencement-authorized-pickup-persons",
            title: "Authorized Persons",
            dataField: 'authorizedPickup',
            type: "inputGroup",
            numberOfResponsesMaximum: 2,
            questions: [
              {
                id: "commencement-authorized-pickup-first-name",
                required: true,
                title: "First Name",
                type: "textInput",
                dataField: 'firstName',
              },
              {
                id: "commencement-authorized-pickup-last-name",
                required: true,
                title: "Last Name",
                type: "textInput",
                dataField: 'lastName'
              }
            ],
            dependencies: [ "commencement-pick-up" ],
          },
          {
            id: "commencement-mail",
            title: "Please mail my diploma to the permanent address below",
            value: "MAIL",
            branch: "commencement-mailing-address",
          },
          {
            id: "commencement-mailing-address",
            title: "Address",
            type: loggedinUserData.mailingAddress,
            dataField: 'mailAddress',
            dependencies: [ "commencement-pick-up" ],
          }
        ],
      }
    ]
  },
  createCommitteeSection()
];

export const additionalFilters = [ {
  title: "Graduation Term",
  id: "termCode",
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.term,
  dataField: 'termCode',
  filter: {
    showFromPresent: 2
  }
},
{
  title: "Graduation Level",
  id: "level",
  required: true,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.graduationLevel, 
  dataField: 'gradLevel',
},
{
  title: "Graduation Plan",
  id: "plan",
  required: true,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.graduatePrograms,
  dataField: 'gradPlan'
},
{
  id: "eos-option",
  title: "End of Semester Option",
  required: false,
  type: inputMappings.dropDown,
  choices: [{
    id: "all-eos",
    title: "All",
    value: "All"
  },
  {
    id: "yes-eos",
    title: "Yes",
    value: "Yes"
  },
  {
    id: "no-eos",
    title: "No",
    value: "No"
  }
  ]
}];

export default data