import {
  dropdownSubtypes,
  inputMappings,
  loggedinUserData,
} from "../../util/enums";

const data = [
  {
    id: "combined-degree-program",
    questions: [
      {
        title: "Current Career",
        id: "career-type",
        required: true,
        type: inputMappings.dropDown,
        dataField: "currentCareer",
        subtype: dropdownSubtypes.careerType,
      },
      {
        title: "Proposed Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        dataField: "gradPlan",
        subtype: dropdownSubtypes.graduatePrograms,
      },
      {
        id: "app-submitted-radio",
        type: inputMappings.checkbox,
        defaultValue: 'N',
        dataField: "isApplicationSubmitted",
        choices: [
          {
            id: "app-submitted-grad-admissions",
            title: "Application Submitted to Graduate Admissions?",
            value: "Y"
          },
        ],
      },
      {
        title: "Matriculation Term",
        id: "matriculation-term",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        dataField: "termCode",
        filter: {
          showFromPresent: 5
        }
      },
      {
        title: "CUM Undergrad GPA",
        id: "cum-undergrad-gpa",
        required: true,
        dataField: "cumUndergradGPA",
        type: loggedinUserData.cumUndergradGpa,
      },
      {
        id: "courses-input-group",
        title: "Courses",
        dataField: "courses",
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        questions: [
          {
            id: "courses-ig-subject-area",
            type: inputMappings.textInput,
            title: "Department",
            summary: null,
            required: true,
            dataField: "department",
            gridProps: {
              xs: 12,
              sm: 6,
              md: 3
            },
          },

          {
            id: "courses-ig-term",
            type: inputMappings.dropDown,
            subtype: dropdownSubtypes.term,
            dataField: "termCode",
            title: "Term",
            summary: null,
            required: true,
            gridProps: {
              xs: 12,
              sm: 6,
              md: 3
            },
            filter: {
              showFromPresent: 8
            }
          },
          {
            id: "courses-ig-number",
            type: inputMappings.textInput,
            title: "Course Number",
            dataField: "courseNumber",
            summary: null,
            required: true,
            gridProps: {
              xs: 12,
              sm: 6,
              md: 3
            },
          },
          {
            id: "courses-ig-credit-hours",
            type: inputMappings.textInput,
            title: "Credit Hours",
            dataField: "creditHours",
            summary: null,
            required: true,
            gridProps: {
              xs: 12,
              sm: 6,
              md: 3
            },
          },
        ],
      },
    ]
  },
];

export const additionalFilters = [ {
  id: "proposed-graduation-plan",
  title: "Proposed Graduate Plans",
  required: false,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.graduatePrograms
}];

export default data;
