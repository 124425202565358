import { lazy } from 'react';
import LogIn from '../Authentication/components/LogIn';
import LogOut from '../Authentication/components/LogOut';
import ExternalLogIn from '../Authentication/containers/ExternalLogIn';
import Mapper from '../Forms/containers/Mapper';
import Unauthorized from './views/Unauthorized';
import formDefinitions from '../Forms/paths';
import List from '../List/components/List';
import { formData } from "../util/enums";

const ContactMaintenance = lazy(() => import('../Maintenance/containers/ContactMaintenance'));
const FacultyMaintenance = lazy(() => import('../Maintenance/containers/FacultyMaintenance'));
const PendingMaintenance = lazy(() => import('../Maintenance/containers/PendingMaintenance'));
const ContactSearch = lazy(() => import('../Search/containers/ContactSearch'));
const FacultySearch = lazy(() => import('../Search/containers/FacultySearch'));
const FormsSearch = lazy(() => import('../Search/containers/FormsSearch'));
const GraduationRecordsSearch = lazy(() => import('../Search/containers/GraduationRecordsSearch'));
const Form = lazy(() => import('../Form/containers/'));
const FormLanding = lazy(() => import('../Form/containers/Landing'));
const Dashboard = lazy(() => import('../Dashboard/containers'));
const Review = lazy(() => import('../Review/containers'));

const getTitle = (params) => {
    const formType = params?.match?.params?.formType
    return formDefinitions?.[formType]?.title
}

const getListTitle = (params) => {
    const listType = params?.match?.params?.listType
    return formData?.[listType]?.area
}

const EXTERNAL_PATHS = [
    { title: 'Tasks', path: '/', Component: (props) => <Review  {...props} /> },
    { title: (params) => {
        let heading = getTitle(params)
        return `View ${heading ? heading + " " : ""}Form`
    }, path: '/form/:formType/view/:formId/:osuid?', Component: Form }
]

const FormComponent = (props) => {
    const formType = props?.match?.params?.formType;
    const formDefinition = (formDefinitions?.[formType] ?? {});
    const FormComponent = formDefinition?.form;
    if(FormComponent) {
        return (<FormComponent {...props} Component={Mapper} />);
    } else {
        return (<Mapper {...props} />);
    }
}

const PATHS = [
    { title: 'Home', path: '/', Component: (props) => <Dashboard student {...props} />, titleProps: { className: 'sr-only' } },
    { title: 'Enrollment Tasks', path: '/enrollment-tasks', Component: (props) => <Review  {...props} /> },
    { title: 'Graduation/Examination Tasks', path: '/graduation-examination-tasks', Component: (props) => <Review  {...props} /> },
    { title: 'Tasks', path: '/tasks', Component: (props) => <Review  {...props} /> },
    { title: 'Contact Maintenance', path: '/admin/contact-maintenance', Component: ContactMaintenance },
    { title: 'Faculty Maintenance', path: '/admin/faculty-maintenance', Component: FacultyMaintenance },
    { title: (params) => {
        let heading = getListTitle(params) 
        return `${heading ? heading : "List"}`
    }, path: '/forms/:listType', Component: List },
    { title: 'Pending Maintenance', path: '/admin/pending-maintenance', Component: PendingMaintenance },
    { title: (params) => {
        let heading = getTitle(params)
        return `Create ${heading ? heading + " " : ""}Form`
    }, path: '/form/:formType/create', Component: FormComponent },
    { title: (params) => {
        let heading = getTitle(params)
        return `View ${heading ? heading + " " : ""}Form`
    }, path: '/form/:formType/view/:formId/:osuid?', Component: Form },
    { title: (params) => {
        let heading = getTitle(params)
        return `Edit ${heading ? heading + " " : ""}Form`
    }, path: '/form/:formType/edit/:formId/:osuid?', Component: FormComponent },
    { title: (params) => {
        const heading = getTitle(params);
        return (heading ? `${heading} Form` : ""); 
    }, path: "/form/:formType", Component: FormLanding },
    { title: 'Contact Search', path: '/search/contact', Component: ContactSearch },
    { title: 'Faculty Search', path: '/search/faculty', Component: FacultySearch },
    { title: 'Forms Search', path: '/search/forms', Component: FormsSearch },
    { title: 'Graduation Records Search', path: '/search/graduationrecords', Component: GraduationRecordsSearch }
];

export const LOGIN_ROUTE = '/login'
export const LOGIN_ROUTE_EXTERNAL = '/external/login'
export const UNAUTHORIZED_ROUTE = '/unauthorized'
const authenticationFlowPaths = [
    { path: LOGIN_ROUTE_EXTERNAL, Component: ExternalLogIn},
    { path: LOGIN_ROUTE, Component: LogIn },
    { path: '/logout', Component: LogOut },
    { path: UNAUTHORIZED_ROUTE, Component: Unauthorized }
];

export {
    authenticationFlowPaths,
    EXTERNAL_PATHS,
    PATHS as default,
};