import React from "react";
import { getDisplayName } from "../../util/functions";

const mutateOptions = (options = [], filter = [], sectionState = {}) =>  {   
  let arr = Array.isArray(options)
    ? options
    : []
  if(!filter?.length) {
    return arr
  }
  filter.forEach((vals) => {
    const {field, match} = vals
    const correspondingState = sectionState?.[field]?.value
    arr = arr.filter(item => {
      return item?.[match] && (item?.[match] === correspondingState?.value)
    })

  })

  return arr
}

function withOptionsFilter(WrappedComponent) {
  function _withOptionsFilter(props) {
    const { options, filter, filterState, sectionState, dataField, ...rest } = props
    
    return <WrappedComponent 
        {...rest}
        options={mutateOptions(options, filter, sectionState)}
    />
  }
  _withOptionsFilter.displayName = `WithDropdownOptions(${getDisplayName(
    WrappedComponent
  )})`;
  return _withOptionsFilter;
}
export default withOptionsFilter;
