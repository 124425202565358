import { dropdownSubtypes, inputMappings } from "../../util/enums";

const data = [
    {
        id: 'form',
        questions: [
            {
                title: "Grad Student OSU ID",
                id: 'osuid',
                required: true,
                type: inputMappings.lookup,
                student: true
            },
            {
                title: "Graduation Level",
                id: "graduation-information-level",
                required: true,
                type: inputMappings.dropDown,
                summary:
                  "If you are uncertain of your academic level, please contact your graduate program coordinator.",
                subtype: dropdownSubtypes.graduationLevel,
                branch: "graduation-information-plan",
                dataField: "gradLevel"
              },
              {
                title: "Graduation Plan",
                id: "graduation-information-plan",
                required: true,
                type: inputMappings.dropDown,
                subtype: dropdownSubtypes.graduatePrograms,
                dataField: "gradPlan",
                filter: [
                  {
                    field: "graduation-information-level",
                    match: "gradLevel",
                  },
                ],
                results: [
                  {
                    dataField: "gradDegree",
                    label: "Graduation Degree",
                    field: "degree",
                  },
                ],
                branch: 'specialization',
                dependencyHelperText:
                  "Please select a level to select the appropriate plan.",
                dependencies: ["graduation-information-level"],
              },
              {
                title: "Specialization",
                id: "specialization",
                required: true,
                type: inputMappings.dropDown,
                dataField: "specialization",
                subtype: dropdownSubtypes.specialization,
                filter: [
                  {
                    field: "graduation-information-plan",
                    match: "gradPlan",
                  },
                ],
                dependencyHelperText:
                  "Please select a plan to select the appropriate specialization.",
                dependencies: ["graduation-information-plan"],
              },
              {
                id: "advisor-info",
                type: inputMappings.dropDown,
                title: "Advisor",
                subtype: dropdownSubtypes.advisor,
                dataField: "advisor",
                dependencies: [
                  "graduation-information-plan"
                ],
                dependencyHelperText: "Please select a plan to select the appropriate advisor.",
                required: true
              }
        ]
    }
]

export default data