import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { dropdownSubtypes, inputMappings } from "../../util/enums";
import { createCommitteeSection } from "./util/shared";

const data = [
  {
    title: "Graduation Information",
    id: "form-information",
    questions: [
      {
        title: "Graduation Term",
        id: "term",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        dataField: "termCode",
        filter: {
          showFromPresent: 2
        }
      },
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        dataField: "gradLevel",
        required: true,
        type: inputMappings.dropDown,
        branch: "graduation-information-plan",
        subtype: dropdownSubtypes.finalExamLevels,
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduatePrograms,
        dataField: 'gradPlan',
        filter: [
          {
            field: "graduation-information-level",
            match: "gradLevel",
          },
        ],
        results: [
          {
            label: "Graduation Degree",
            field: "degree",
            dataField: 'gradDegree'
          },
        ],
        dependencyHelperText:
          "Please select a level to select the appropriate plan.",
        dependencies: ["graduation-information-level"],
      },

      {
        title: "Final Examination Type",
        id: "final-exam-type",
        required: true,
        type: inputMappings.dropDown,
        dataField: 'finalExamType',
        choices: [
          {
            id: "first-final-exam",
            title: "First Final Exam",
            value: "first-final-exam",
          },
          {
            id: "second-final-exam",
            title: "Second Final Exam",
            value: "second-final-exam",
          },
        ],
      },
    ],
  },
  {
    id: "draft-dissertation-dma",
    title: "Draft Dissertation/D.M.A",
    summary: (
      <Fragment>
        <Mui.Typography variant="body2" className="margin-bottom-2">
          The Dean of the Graduate School appoints the Graduate Faculty
          Representative after the Graduate School is notified of the proposed
          date of the final oral examination. No less than one week before the
          final oral examination, a completed digital dissertation/DMA draft
          must be presented to the Graduate Faculty Representative by the
          student or his/her representative.
        </Mui.Typography>
        <Mui.Typography variant="body2" className="margin-bottom-2">
          Please note that a complete paper draft copy of your dissertation must
          be submitted to the Graduate School for a format review at least two
          weeks before the examination date. Your exam cannot be approved to go
          forward until the format review has been completed.
        </Mui.Typography>
      </Fragment>
    ),
    questions: [
      {
        id: "draft-dissertation-title",
        title: "Draft Dissertation Title",
        type: inputMappings.textInput,
        dataField: "draftDissertationTitle",
        required: true,
      },
    ],
  },
  {
    id: "oral-portion",
    title: "Oral portion of final examination",
    summary: (
      <Fragment>
        <Mui.Typography variant="body2" className="margin-bottom-2">
          Please note that if you will conduct a presentation of your
          dissertation research before the final oral examination, only indicate
          the time frame of the actual defense on this form.
        </Mui.Typography>
        <Mui.Typography variant="body2" className="margin-bottom-2">
          The final oral examination must be on a University business day and
          must begin no earlier than 7:30 AM, but end no later than 5:30 PM, and
          must be at least two hours in length.
        </Mui.Typography>
      </Fragment>
    ),
    questions: [
      {
        id: "date-input-group",
        title: "Date of Oral Examination",
        type: inputMappings.datetime,
        startTime: true,
        endTime: true,
        validation: {
          begin: '7:30 AM',
          end: '5:30 PM',
          durationInHours: 2,
          weekend: true
        },
        required: true,
        dataField: "oralDate"
      },
      {
        id: 'video-conf-component',
        type: inputMappings.checkbox,
        defaultValue: 'N',
        choices: [
          {
            id: 'yes-video-conf-component',
            value: 'Y',
            title: 'There will be a video conference component to this examination.',
            branch: 'video-conf-platform',
            dataField: "videoConference"
          }
        ]
      },
      {
        id: 'video-conf-platform',
        title: 'Video Conference Platform (example: Zoom meeting, Microsoft Teams, Skype, etc.)',
        dependencies: [
          'video-conf-component'
        ],
        dataField: "videoConferencePlatform",
        type: inputMappings.textInput,
        summary: <Fragment>
          <Mui.Typography variant="body2" className="margin-bottom-2">For examinations that are entirely virtual you do not need to include room and building.</Mui.Typography>
          <Mui.Typography variant="body2">If your examination is both virtual and in person please include room and building.</Mui.Typography>
        </Fragment>
      },
      {
        id: 'room-number-building',
        type: inputMappings.inputGroup,
        numberOfResponsesMaximum: 1,
        questions: [
          {
            id: 'room-num',
            title: 'Room Number',
            dataField: "roomNumber",
            type: inputMappings.textInput
          },
          {
            id: 'building',
            title: 'Building',
            dataField: "building",
            type: inputMappings.textInput
          }
        ]
      }
    ]
  },
  createCommitteeSection()
];

export const additionalFilters = [ {
  id: "oral-date-after",
  title: "Oral Date After",
  type: inputMappings.date,
  startTime: false,
  endTime: false,
},
{
  id: "oral-date-before",
  title: "Oral Date Before",
  type: inputMappings.date,
  startTime: false,
  endTime: false,
},
{
  id: "exam-type",
  title: "Exam Type",
  required: false,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.examType
},
{
  id: "category-m",
  type: "checkbox",
  required: false,
  defaultValue: 'N',
  choices: [
    {
      id: "pro-request-restrict-understanding-confirmation",
      title: "Category M",
      value: "Y",
    },
  ]
}];



export default data;
