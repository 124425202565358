/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPerson = /* GraphQL */ `
  query GetPerson($emplid: String!) {
    getPerson(emplid: $emplid) {
      emplid
      nameN
      firstName
      lastName
      middleName
      name
      facultyEmail
      facultyNomination
      phone
      address1
      program
      category
      __typename
    }
  }
`;
export const getAcademicPlans = /* GraphQL */ `
  query GetAcademicPlans($filter: AcademicPlansFilter) {
    getAcademicPlans(filter: $filter) {
      academicPlans {
        academicPlan
        degree
        gradLevel
        status
        acadSubPlan
        graduateProgram
        acadProg
        acadProgDescr
        descr
        syncDateTime
        __typename
      }
      status
      lastModified
      __typename
    }
  }
`;
export const getGraduateContactAffiliationComments = /* GraphQL */ `
  query GetGraduateContactAffiliationComments(
    $emplid: String!
    $academicPlan: String!
    $affiliation: String!
  ) {
    getGraduateContactAffiliationComments(
      emplid: $emplid
      academicPlan: $academicPlan
      affiliation: $affiliation
    ) {
      comments {
        emplid
        submittedById
        submittedByName
        submittedDateTime
        text
        academicPlan
        affiliation
        program
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getGraduateContactAffiliationWorkflowHistory = /* GraphQL */ `
  query GetGraduateContactAffiliationWorkflowHistory(
    $emplid: String!
    $academicPlan: String!
    $affiliation: String!
  ) {
    getGraduateContactAffiliationWorkflowHistory(
      emplid: $emplid
      academicPlan: $academicPlan
      affiliation: $affiliation
    ) {
      lastModified
      status
      workflowHistory {
        ... on OneStepApprovalWorkflowHistoryItem {
          __typename
          description
          status
          submittedBy
          submittedDate
        }
      }
      __typename
    }
  }
`;
export const getGraduateContacts = /* GraphQL */ `
  query GetGraduateContacts($filter: GraduateContactsFilter) {
    getGraduateContacts(filter: $filter) {
      graduateContacts {
        affiliations {
          academicPlan
          active
          affiliation
          pending
          program
          syncDateTime
          viewOnly
          wfHistory
          pendingActive
          pendingNew
          pendingViewOnly
          wfId
          __typename
        }
        building
        campus
        email
        emplid
        firstName
        lastName
        middleInitial
        phone
        status
        street
        syncDateTime
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getGraduateContactsPending = /* GraphQL */ `
  query GetGraduateContactsPending {
    getGraduateContactsPending {
      graduateContacts {
        affiliations {
          academicPlan
          active
          affiliation
          pending
          program
          syncDateTime
          viewOnly
          wfHistory
          pendingActive
          pendingNew
          pendingViewOnly
          wfId
          __typename
        }
        building
        campus
        email
        emplid
        firstName
        lastName
        middleInitial
        phone
        status
        street
        syncDateTime
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getGraduateFaculty = /* GraphQL */ `
  query GetGraduateFaculty($filter: GraduateFacultyFilter) {
    getGraduateFaculty(filter: $filter) {
      graduateFaculty {
        appointments {
          active
          category
          facultyType
          pending
          program
          syncDateTime
          wfHistory
          pendingActive
          pendingCategory
          pendingFacultyType
          pendingNew
          wfId
          __typename
        }
        building
        email
        emplid
        firstName
        lastName
        middleInitial
        namedotn
        phone
        status
        street
        syncDateTime
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getGraduateFacultyAppointmentComments = /* GraphQL */ `
  query GetGraduateFacultyAppointmentComments(
    $emplid: String!
    $program: String!
  ) {
    getGraduateFacultyAppointmentComments(emplid: $emplid, program: $program) {
      comments {
        emplid
        submittedById
        submittedByName
        submittedDateTime
        text
        academicPlan
        affiliation
        program
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getGraduateFacultyAppointmentWorkflowHistory = /* GraphQL */ `
  query GetGraduateFacultyAppointmentWorkflowHistory(
    $emplid: String!
    $program: String!
  ) {
    getGraduateFacultyAppointmentWorkflowHistory(
      emplid: $emplid
      program: $program
    ) {
      lastModified
      status
      workflowHistory {
        ... on OneStepApprovalWorkflowHistoryItem {
          __typename
          description
          status
          submittedBy
          submittedDate
        }
      }
      __typename
    }
  }
`;
export const getGraduateFacultyPending = /* GraphQL */ `
  query GetGraduateFacultyPending {
    getGraduateFacultyPending {
      graduateFaculty {
        appointments {
          active
          category
          facultyType
          pending
          program
          syncDateTime
          wfHistory
          pendingActive
          pendingCategory
          pendingFacultyType
          pendingNew
          wfId
          __typename
        }
        building
        email
        emplid
        firstName
        lastName
        middleInitial
        namedotn
        phone
        status
        street
        syncDateTime
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const searchGraduateFaculty = /* GraphQL */ `
  query SearchGraduateFaculty(
    $query: String
    $program: String
    $active: GraduateFacultyAppointmentActive
  ) {
    searchGraduateFaculty(query: $query, program: $program, active: $active) {
      graduateFaculty {
        appointments {
          active
          category
          facultyType
          pending
          program
          syncDateTime
          wfHistory
          pendingActive
          pendingCategory
          pendingFacultyType
          pendingNew
          wfId
          __typename
        }
        building
        email
        emplid
        firstName
        lastName
        middleInitial
        namedotn
        phone
        status
        street
        syncDateTime
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getJobCodes = /* GraphQL */ `
  query GetJobCodes {
    getJobCodes {
      jobCodes {
        jobCode
        jobTitle
        jobType
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getDegrees = /* GraphQL */ `
  query GetDegrees {
    getDegrees {
      degrees {
        degree
        gradLevel
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getGraduationRecords = /* GraphQL */ `
  query GetGraduationRecords(
    $emplid: String!
    $partialSk: String!
    $filter: GraduationRecordsFilter
  ) {
    getGraduationRecords(
      emplid: $emplid
      partialSk: $partialSk
      filter: $filter
    ) {
      graduationRecords {
        emplid
        name
        email
        degreeNumber
        degree
        academicPlan
        completionTerm
        programDescription
        transcriptDescription
        academicCareer
        academicProgram
        completionTermDescr
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getTerms = /* GraphQL */ `
  query GetTerms($career: String!, $future: String, $history: String) {
    getTerms(career: $career, future: $future, history: $history) {
      terms {
        shortDescr
        strm
        academicYear
        descr
        termBeginDt
        termEndDt
        termStatus
        __typename
      }
      status
      lastModified
      __typename
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($formType: String!, $formId: String!, $studentid: String) {
    getForm(formType: $formType, formId: $formId, studentid: $studentid) {
      form {
        id
        studentid
        academicPlan
        careerNumber
        name
        completedBy
        completedDate
        createdBy
        createdDate
        lastUpdatedBy
        lastUpdatedDate
        submittedBy
        submittedDate
        formType
        status
        substatus
        authorizedPickup {
          firstName
          lastName
          email
          __typename
        }
        action
        termCode
        gradLevel
        endOfSemesterOption
        commencement
        gradPlan
        gradDegree
        finalExamType
        draftDissertationTitle
        oralDate {
          date
          start
          end
          __typename
        }
        videoConference
        videoConferencePlatform
        roomNumber
        building
        mailAddress {
          address1
          address2
          city
          country
          name
          postalCode
          state
          __typename
        }
        committee {
          advisor {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          coAdvisor {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          gradFacultyRepresentative {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          members {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          externalMembers {
            firstName
            lastName
            email
            __typename
          }
          __typename
        }
        advisor {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        courses {
          action
          classNumber
          courseNumber
          courseTitle
          courseSubject
          creditHours
          instructor
          institution
          reason
          department
          skType
          termCode
          __typename
        }
        fromTermCode
        toTermCode
        concentrationType
        designation
        currentCareer
        applicationType
        reasonForPetition
        specialization
        academicUnit
        gradProgram
        professionalExamType
        beginDate
        endDate
        pcdIndicator
        completionTerm
        certificatePlan
        requesterId
        reason
        otherReason
        creditType
        isApplicationSubmitted
        cumUndergradGPA
        ohioLink
        delayPeriod
        disclosureOhioLink
        disclosureProRequestUnderstanding
        disclosureProRequestConfirmation
        candidacyType
        requestGradFacultyRep
        currentGradLevel
        proposedAcadLevel
        proposedAdvisor {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        proposedAcadPlan
        ruleWaiverRuleId
        formerCommMemberServingAs
        reasonForPetitionComment
        attachments
        osuid
        justification
        facultyMember {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        street
        phone
        facultyRank
        highestDegree
        osuDegree
        degreeInstitution
        degreeYear
        category
        proposedGradProgram
        proposedCategory
        comments
        categoryMApproved
        __typename
      }
      errors {
        field
        subfield
        type
        __typename
      }
      lastModified
      status
      action
      wfId
      wfType
      __typename
    }
  }
`;
export const getFormHistory = /* GraphQL */ `
  query GetFormHistory($emplid: String!, $formType: String!, $formId: String!) {
    getFormHistory(emplid: $emplid, formType: $formType, formId: $formId) {
      history {
        step
        status
        items {
          action
          date
          description
          emplid
          name
          task
          taskActions
          __typename
        }
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getForms = /* GraphQL */ `
  query GetForms($filter: FormsFilter!) {
    getForms(filter: $filter) {
      forms {
        id
        studentid
        academicPlan
        careerNumber
        name
        completedBy
        completedDate
        createdBy
        createdDate
        lastUpdatedBy
        lastUpdatedDate
        submittedBy
        submittedDate
        formType
        status
        substatus
        authorizedPickup {
          firstName
          lastName
          email
          __typename
        }
        action
        termCode
        gradLevel
        endOfSemesterOption
        commencement
        gradPlan
        gradDegree
        finalExamType
        draftDissertationTitle
        oralDate {
          date
          start
          end
          __typename
        }
        videoConference
        videoConferencePlatform
        roomNumber
        building
        mailAddress {
          address1
          address2
          city
          country
          name
          postalCode
          state
          __typename
        }
        committee {
          advisor {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          coAdvisor {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          gradFacultyRepresentative {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          members {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          externalMembers {
            firstName
            lastName
            email
            __typename
          }
          __typename
        }
        advisor {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        courses {
          action
          classNumber
          courseNumber
          courseTitle
          courseSubject
          creditHours
          instructor
          institution
          reason
          department
          skType
          termCode
          __typename
        }
        fromTermCode
        toTermCode
        concentrationType
        designation
        currentCareer
        applicationType
        reasonForPetition
        specialization
        academicUnit
        gradProgram
        professionalExamType
        beginDate
        endDate
        pcdIndicator
        completionTerm
        certificatePlan
        requesterId
        reason
        otherReason
        creditType
        isApplicationSubmitted
        cumUndergradGPA
        ohioLink
        delayPeriod
        disclosureOhioLink
        disclosureProRequestUnderstanding
        disclosureProRequestConfirmation
        candidacyType
        requestGradFacultyRep
        currentGradLevel
        proposedAcadLevel
        proposedAdvisor {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        proposedAcadPlan
        ruleWaiverRuleId
        formerCommMemberServingAs
        reasonForPetitionComment
        attachments
        osuid
        justification
        facultyMember {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        street
        phone
        facultyRank
        highestDegree
        osuDegree
        degreeInstitution
        degreeYear
        category
        proposedGradProgram
        proposedCategory
        comments
        categoryMApproved
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const searchForms = /* GraphQL */ `
  query SearchForms($filter: SearchFormsFilter) {
    searchForms(filter: $filter) {
      forms {
        id
        studentid
        academicPlan
        careerNumber
        name
        completedBy
        completedDate
        createdBy
        createdDate
        lastUpdatedBy
        lastUpdatedDate
        submittedBy
        submittedDate
        formType
        status
        substatus
        authorizedPickup {
          firstName
          lastName
          email
          __typename
        }
        action
        termCode
        gradLevel
        endOfSemesterOption
        commencement
        gradPlan
        gradDegree
        finalExamType
        draftDissertationTitle
        oralDate {
          date
          start
          end
          __typename
        }
        videoConference
        videoConferencePlatform
        roomNumber
        building
        mailAddress {
          address1
          address2
          city
          country
          name
          postalCode
          state
          __typename
        }
        committee {
          advisor {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          coAdvisor {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          gradFacultyRepresentative {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          members {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          externalMembers {
            firstName
            lastName
            email
            __typename
          }
          __typename
        }
        advisor {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        courses {
          action
          classNumber
          courseNumber
          courseTitle
          courseSubject
          creditHours
          instructor
          institution
          reason
          department
          skType
          termCode
          __typename
        }
        fromTermCode
        toTermCode
        concentrationType
        designation
        currentCareer
        applicationType
        reasonForPetition
        specialization
        academicUnit
        gradProgram
        professionalExamType
        beginDate
        endDate
        pcdIndicator
        completionTerm
        certificatePlan
        requesterId
        reason
        otherReason
        creditType
        isApplicationSubmitted
        cumUndergradGPA
        ohioLink
        delayPeriod
        disclosureOhioLink
        disclosureProRequestUnderstanding
        disclosureProRequestConfirmation
        candidacyType
        requestGradFacultyRep
        currentGradLevel
        proposedAcadLevel
        proposedAdvisor {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        proposedAcadPlan
        ruleWaiverRuleId
        formerCommMemberServingAs
        reasonForPetitionComment
        attachments
        osuid
        justification
        facultyMember {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        street
        phone
        facultyRank
        highestDegree
        osuDegree
        degreeInstitution
        degreeYear
        category
        proposedGradProgram
        proposedCategory
        comments
        categoryMApproved
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getStudentAlerts = /* GraphQL */ `
  query GetStudentAlerts($osuid: String) {
    getStudentAlerts(osuid: $osuid) {
      alerts {
        title
        subtitle
        description
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const getTasks = /* GraphQL */ `
  query GetTasks {
    getTasks {
      tasks {
        academicPlan
        creationTime
        formId
        formType
        requesterId
        stage
        studentid
        studentName
        __typename
      }
      status
      lastModified
      __typename
    }
  }
`;
export const getStudentInfo = /* GraphQL */ `
  query GetStudentInfo($studentid: String) {
    getStudentInfo(studentid: $studentid) {
      status
      lastModified
      record {
        name
        email
        address {
          address1
          address2
          city
          country
          name
          postalCode
          state
          __typename
        }
        phone
        osuid
        acadPlan
        admitTerm
        degree
        withdrawn
        feesPaid
        postCandidacy
        earnedHours
        cumulativeHours
        cumulativeGPA
        missingGrades
        asOfTerm
        __typename
      }
      __typename
    }
  }
`;
export const getExternalUserInfo = /* GraphQL */ `
  query GetExternalUserInfo {
    getExternalUserInfo {
      externalUserId
      emailAddr
      firstName
      lastName
      createdDt
      lastUpdatedDt
      roleName
      __typename
    }
  }
`;
export const getUserDetails = /* GraphQL */ `
  query GetUserDetails {
    getUserDetails {
      userDetails {
        academicPlan
        applicationRoles
        careerNumber
        degree
        email
        fullName
        initials
        name
        osuid
        pcdIndicator
        __typename
      }
      status
      lastModified
      __typename
    }
  }
`;
