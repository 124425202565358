import { connect } from "react-redux";
import Mapper from "../components/Mapper";
import formDefinitions from "../paths";
import { formatInitialState } from "../util";
import { RESET_DROPDOWNS, RESET_TEMPLATE_FORM, SUBMIT_TEMPLATE_FORM, onFormSave, onFormSubmit, getSections, resetState } from "../../actions-index";
import { generateAction } from "../../redux-helpers";
import { ACTION_STATUS } from "../../util/constants";

const mapStateToProps = (state, ownProps) => {
  const formTypeFriendly = ownProps?.match?.params?.formType;
  const formId = ownProps?.match?.params?.formId;
  const { sections, formType } = formDefinitions?.[formTypeFriendly] || {};
  const editScreen = ownProps?.match?.pathname?.includes('/edit/')
  const submissionId = state?.form?.submission?.id
  const submissionOSUID = state?.form?.submission?.osuid
  const submissionStatus = state?.form?.submission?.status;
  const submissionErrorMessage = state?.form?.submission?.errorMessage;
  const isValidating = (ownProps?.isValidating ?? false);

  const existingEditableForm = (state?.form?.edit?.[formId]?.form ?? state?.form?.edit?.[submissionId]?.form ?? {});
 let initialState = {}
  const { initialState: is, formSections } = formatInitialState(
    sections
  );
  if(Object.keys(existingEditableForm).length) {
    initialState = existingEditableForm
  } else {
    initialState = is
  }
  const errorState = (state?.templateForm?.errors?.[formId]?.state ?? state?.templateForm?.errors?.[formTypeFriendly]?.state);
  const formStatus = (submissionStatus ?? state?.form?.edit?.[formId]?.status)
  const loadGetFormCall = editScreen && !Object.keys(existingEditableForm).length && !formStatus
  const loading = (
    isValidating === true || 
    (!existingEditableForm && loadGetFormCall) || 
    ['initialized', 'loading'].includes(state?.form?.edit?.[formId]?.status) ||
    submissionStatus === ACTION_STATUS.LOADING
  );
  const user = (state?.authentication?.user ?? {});
  const navigationMessage = state?.form?.[formId]?.navigationMessage;

  return {
    formId,
    formType,
    formTypeFriendly,
    initialState,
    errorState,
    formSections,
    loadGetFormCall,
    loading,
    navigationMessage,
    submissionId,
    submissionOSUID,
    submissionStatus,
    submissionErrorMessage,
    user,
    status: formStatus
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSave: (values) => dispatch(onFormSave(values)),
    onSubmit: (values) => dispatch(onFormSubmit(values)),
    getSections: (filter = {}) => dispatch(getSections(filter)),
    resetFormError: () => dispatch(generateAction(RESET_TEMPLATE_FORM, {})),
    resetSubmission: () => dispatch(resetState(SUBMIT_TEMPLATE_FORM), {}),
    resetDropdowns: () => dispatch(generateAction(RESET_DROPDOWNS, {}))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapper);
