import { find } from "lodash";
import ApplicationForCandidacyExamForm from "../components/ApplicationForCandidacyExam.js";
import ApplicationFormCandidacyExamApprovalForm from "../components/Approval/ApplicationForCandidacyExam.js";
import * as ApplicationForCandidacyExamData from "../data/ApplicationForCandidacyExam.js";
import appToGraduate, { additionalFilters as additionalFiltersATG } from "../data/application-to-graduate.js"
import leaveAbsence from "../data/leave-absence-program"
import delayFinalDoc from "../data/delay-of-final-document"
import minorInterdisciplinary, { additionalFilters as additionalFiltersMAS } from "../data/minors-interdisciplinary"
import combinedDegree, { additionalFilters as additionalFiltersCDP } from "../data/combined-degree-program"
import dualProgram from "../data/dual-program"
import withdrawalFromProgram from "../data/withdrawal-from-program"
import lateCoursePetition from "../data/late-course-petition"
import transferGradCredit, { additionalFilters as additionalFiltersTGC } from "../data/transfer-grad-credit"
import appFinalExam, { additionalFilters as additionalFiltersAFE} from "../data/app-for-final-examination"
import caertificationCompletion, { additionalFilters as additionalFiltersAFC } from "../data/app-for-certification"
import committeeExamination, { additionalFilters as additionalFiltersCAE } from "../data/committe-and-examination"
import specialization from "../data/specialization"
import GraduateFacultyNominationForm from "../components/GraduateFacultyNomination.js";
import gradFacultyForm from "../data/graduate-faculty-form"
import professionalExamination, { additionalFilters as additionalFiltersAPE } from "../data/application-for-professional-examination"
import { APPLICATION_ROLE, formTypeIdentifiers, FORM_TYPE, FORM_TYPE_HTML_FRIENDLY } from "../../util/constants.js"
import { formData } from "../../util/enums";

export const formDefinitions = {
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.GRAD_FACULTY_NOMINATION]]: {
        title: FORM_TYPE[formTypeIdentifiers.GRAD_FACULTY_NOMINATION],
        form: GraduateFacultyNominationForm,
        sections: gradFacultyForm,
        formType: formTypeIdentifiers.GRAD_FACULTY_NOMINATION,
        isStudentForm: false,
        canCreateForm: true,
        contextFields: {
            academicPlan: "proposedGradProgram",
            studentid: "facultyMember.emplid"
        },
        pk: "facultyMember.emplid",
        formDetailFields: ["academicUnit", "proposedCategory", "proposedGradProgram"] // TODO: How to display Graduate Faculty first and last name together?
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.SPECIALIZATION]]: {
        title: FORM_TYPE[formTypeIdentifiers.SPECIALIZATION],
        sections: specialization,
        formType: formTypeIdentifiers.SPECIALIZATION,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN, APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: false,
        canCreateForm: true,
        contextFields: {
            academicPlan: "gradPlan",
            studentid: "osuid.emplid",
            name: "osuid.name"
        },
        formDetailFields: ["specialization"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM],
        form: ApplicationForCandidacyExamForm,
        sections: ApplicationForCandidacyExamData.formSections,
        approvalForm: ApplicationFormCandidacyExamApprovalForm,
        formType: formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: ApplicationForCandidacyExamData.additionalFilters,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "academicPlan", "candidacyType", "categoryMApproved"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_CANDIDACY]]: {
        title: FORM_TYPE[formTypeIdentifiers.REPORT_ON_CANDIDACY],
        formType: formTypeIdentifiers.REPORT_ON_CANDIDACY,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["reports"].forms, ["name", FORM_TYPE[formTypeIdentifiers.REPORT_ON_CANDIDACY]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "beginDate", "endDate"] // TODO: How to show the begin and end date as time?
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION]]: {
        title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION],
        sections: caertificationCompletion,
        formType: formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: additionalFiltersAFC,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION]])?.canCreate ?? false),
        formDetailFields: ["termCode", "certificatePlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_TO_GRADUATE]]: {
        title: FORM_TYPE[formTypeIdentifiers.APPLICATION_TO_GRADUATE],
        sections: appToGraduate,
        formType: formTypeIdentifiers.APPLICATION_TO_GRADUATE,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: additionalFiltersATG,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.APPLICATION_TO_GRADUATE]])?.canCreate ?? false),
        formDetailFields: ["termCode", "gradLevel", "gradPlan", "gradDegree", "endOfSemesterOption"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM],
        sections: appFinalExam,
        formType: formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: additionalFiltersAFE,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "gradPlan", "finalExamType"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_FINAL_EXAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.REPORT_ON_FINAL_EXAM],
        formType: formTypeIdentifiers.REPORT_ON_FINAL_EXAM,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["reports"].forms, ["name", FORM_TYPE[formTypeIdentifiers.REPORT_ON_FINAL_EXAM]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "academicPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT]]: {
        title: FORM_TYPE[formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT],
        formType: formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["reports"].forms, ["name", FORM_TYPE[formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "academicPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.TRANSCRIPT_REQUEST]]: {
        title: FORM_TYPE[formTypeIdentifiers.TRANSCRIPT_REQUEST],
        formType: formTypeIdentifiers.TRANSCRIPT_REQUEST,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: false,
        formDetailFields: ["concentrationType", "designation"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT]]: {
        title: FORM_TYPE[formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT],
        sections: delayFinalDoc,
        formType: formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT]])?.canCreate ?? false),
        formDetailFields: ["gradLevel", "gradPlan", "gradDegree"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION]]: {
        title: FORM_TYPE[formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION],
        sections: committeeExamination,
        formType: formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: additionalFiltersCAE,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION]])?.canCreate ?? false),
        formDetailFields: ["gradPlan", "applicationType", "reasonForPetition"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_PROF_EXAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_PROF_EXAM],
        sections: professionalExamination,
        formType: formTypeIdentifiers.APPLICATION_FOR_PROF_EXAM,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: additionalFiltersAPE,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_PROF_EXAM]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "academicPlan", "professionalExamType", "categoryMApproved"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_PROF_EXAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.REPORT_ON_PROF_EXAM],
        formType: formTypeIdentifiers.REPORT_ON_PROF_EXAM,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["reports"].forms, ["name", FORM_TYPE[formTypeIdentifiers.REPORT_ON_PROF_EXAM]])?.canCreate ?? false),
        formDetailFields: ["beginDate", "professionalExamType"] // TODO: How to show begin date and time separately?
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.COMBINED_DEGREE_PROGRAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.COMBINED_DEGREE_PROGRAM],
        sections: combinedDegree,
        type: FORM_TYPE.ENROLLMENT,
        formType: formTypeIdentifiers.COMBINED_DEGREE_PROGRAM,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        additionalFilters: additionalFiltersCDP,
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.COMBINED_DEGREE_PROGRAM]])?.canCreate ?? false),
        formDetailFields: ["currentCareer", "gradPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.DUAL_DEGREE_PROGRAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.DUAL_DEGREE_PROGRAM],
        sections: dualProgram,
        type: FORM_TYPE.ENROLLMENT,
        formType: formTypeIdentifiers.DUAL_DEGREE_PROGRAM,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.DUAL_DEGREE_PROGRAM]])?.canCreate ?? false),
        formDetailFields: ["gradPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.LATE_COURSE_PETITION]]: {
        title: FORM_TYPE[formTypeIdentifiers.LATE_COURSE_PETITION],
        sections: lateCoursePetition,
        type: FORM_TYPE.ENROLLMENT,
        formType: formTypeIdentifiers.LATE_COURSE_PETITION,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.LATE_COURSE_PETITION]])?.canCreate ?? false),
        formDetailFields: ["termCode", "courses.action", "courses.courseSubject", "courses.courseNumber", "courses.classNumber", "courses.instructor"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM],
        sections: leaveAbsence,
        formType: formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM]])?.canCreate ?? false),
        formDetailFields: ["pcdIndicator", "gradPlan", "fromTermCode", "toTermCode"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS]]: {
        title: FORM_TYPE[formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS],
        sections: minorInterdisciplinary,
        formType: formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        additionalFilters: additionalFiltersMAS,
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS]])?.canCreate ?? false),
        formDetailFields: ["concentrationType", "designation"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.TRANSFER_GRAD_CREDIT]]: {
        title: FORM_TYPE[formTypeIdentifiers.TRANSFER_GRAD_CREDIT],
        sections: transferGradCredit,
        type: FORM_TYPE.ENROLLMENT,
        formType: formTypeIdentifiers.TRANSFER_GRAD_CREDIT,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        additionalFilters: additionalFiltersTGC,
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.TRANSFER_GRAD_CREDIT]])?.canCreate ?? false),
        formDetailFields: ["gradPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM],
        sections: withdrawalFromProgram,
        type: FORM_TYPE.ENROLLMENT,
        formType: formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM]])?.canCreate ?? false),
        formDetailFields: ["gradPlan"]
    }
}

export const links = Object.keys(formDefinitions).map(id => {
    return {
        path: `/forms/${id}`,
        title: formDefinitions[id].title
    }
})

const getFormByType  = (type) => {
    const entries = Object.values(formDefinitions)
    const form = entries.find(form => form.formType === type)

    return form
}

export { 
    formDefinitions as default,
    getFormByType
}
