import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APPROVAL_STATUS } from "../../util/constants";

export default function useFormSubmission(props = {}) {
    const navigate = useNavigate();
    const user = useSelector((state) => (state?.authentication?.user ?? {}));
    const { isEnrollmentAdmin, isGraduationAdmin } = user;
    const osuid = (props?.osuid ?? user.osuid);

    const navigateToFormLandingPage = useCallback((formTypeFriendly, errorMessage = null) => {
        const options = {};
        if(errorMessage) options.state = { errorMessage };
        navigate(`/form/${formTypeFriendly}`, options);
    }, [navigate]);

    const isUserCreatorOfForm = useCallback((form) => {
        return (form.createdBy === osuid);
    }, [osuid]);

    const isUserStudentOnForm = useCallback((form) => {
        return (form.studentid === osuid);
    }, [osuid]);

    const validateEditPermitted = useCallback((form) => {
        let error = null;
        if((isEnrollmentAdmin || isGraduationAdmin)) {
            if([APPROVAL_STATUS.CANCELLED, APPROVAL_STATUS.COMPLETED].includes(form.status)) {
                error = "This form cannot be edited once it has been completed or cancelled.";
            }
        } else {
            if((!isUserStudentOnForm(form) && !isUserCreatorOfForm(form)) || form.status !== APPROVAL_STATUS.NEW) {
                error = "This form cannot be edited once it has been submitted.";
            }
        }
        return error;
    }, [isUserCreatorOfForm, isUserStudentOnForm, isEnrollmentAdmin, isGraduationAdmin]);

    return {
        navigateToFormLandingPage,
        validateEditPermitted
    };
}