/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "gradforms-20220729102449-hostingbucket-qa",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1svd59s7y6v0j.cloudfront.net"
};


export default awsmobile;
