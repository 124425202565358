import { dropdownSubtypes, inputMappings } from "../../util/enums";

const data = [
  {
    id: "form",
    questions: [
      {
        id: "completion-term",
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        title: "Completion Term",
        dataField: "termCode",
        required: true,
        filter: {
          showFromPresent: 2
        }
      },
      {
        id: "certificate-plan",
        type: inputMappings.dropDown,
        dataField: "certificatePlan",
        subtype: dropdownSubtypes.certicatePlans,
        title: "Certificate Plan",
        required: true,
      },
      {
        id: "courses-input-group",
        title: "Courses",
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        dataField: "courses",
        questions: [
          {
            id: "courses-ig-department",
            type: "textInput",
            title: "Department",
            summary: null,
            required: true,
            dataField: "department",
            gridProps: {
                xs: 12,
                sm: 8,
                md: 4,
              }
          },
          {
            id: "courses-ig-number",
            type: "textInput",
            title: "Course Number",
            dataField: "courseNumber",
            summary: null,
            required: true,
            gridProps: {
                xs: 12,
                sm: 4,
                md: 2,
              }
          },
          {
            id: "courses-ig-title",
            type: "textInput",
            title: "Course Title",
            dataField: "courseTitle",
            summary: null,
            required: true,
            gridProps: {
                xs: 12,
                sm: 8,
                md: 4,
              }
          },
          {
            id: "courses-ig-credit-hours",
            type: "textInput",
            title: "Credit Hours",
            dataField: "creditHours",
            summary: null,
            required: true,
            gridProps: {
                xs: 12,
                sm: 4,
                md: 2,
              }
          },
        ],
    }

    ]
  },
];

export const filters = [
  {
    id: "term",
    type: inputMappings.dropDown,
    subtype: dropdownSubtypes.term,
    title: "Completion Term",
    required: true,
    filter: {
      showFromPresent: 2
    }
  }
]

export const additionalFilters = [ {
  title: "Completion Term",
  id: "completionTerm",
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.term,
  dataField: 'completionTerm',
  filter: {
    showFromPresent: 2
  }
}
];


export default data