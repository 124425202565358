import { generateClient } from "aws-amplify/api";
import { 
    GRADUATE_CONTACT_AFFILIATION_COMMENT, GRADUATE_CONTACT_AFFILIATION_COMMENTS, GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY, GRADUATE_CONTACT_APPROVE,
    GRADUATE_CONTACT_DENY, GRADUATE_CONTACT_SUBMIT, GRADUATE_CONTACTS, GRADUATE_CONTACTS_PENDING, GRADUATE_FACULTY_APPOINTMENT_COMMENT,
    GRADUATE_FACULTY_APPOINTMENT_COMMENT_RESET, GRADUATE_FACULTY_APPOINTMENT_COMMENTS, GRADUATE_FACULTY_APPOINTMENT_COMMENTS_RESET,
    GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY, GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY_RESET, GRADUATE_FACULTY_APPROVE, GRADUATE_FACULTY_DENY,
    GRADUATE_FACULTY, GRADUATE_FACULTY_PENDING, GRADUATE_FACULTY_SUBMIT, GRADUATE_PROGRAM_MAP
} from "../actions-index.js";
import { transformGraduateContacts, transformGraduateFaculty } from "./transform";
import * as Mutations from '../graphql/mutations';
import * as Queries from '../graphql/queries';
import { ACTION_STATUS, DEGREE } from "../util/constants";
import { buildAction } from "../util/functions";

const API = generateClient();

export function getGraduateProgramMap() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_PROGRAM_MAP, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getAcademicPlans, variables: {} });
            const academicPlans = (response?.data?.getAcademicPlans?.academicPlans ?? []);
            const graduateProgramMap = {};
            for(const academicPlan of academicPlans) {
                if(academicPlan.degree !== DEGREE.SPECIALIZATION) {
                    if(!graduateProgramMap[academicPlan.graduateProgram]) {
                        graduateProgramMap[academicPlan.graduateProgram] = { acadProgDescr: "", academicPlans: [] };
                    }
                    if(academicPlan.acadProgDescr) graduateProgramMap[academicPlan.graduateProgram].acadProgDescr = academicPlan.acadProgDescr;
                    graduateProgramMap[academicPlan.graduateProgram].academicPlans.push({ academicPlan: academicPlan.academicPlan, descr: academicPlan.descr, status: academicPlan.status });
                }
            }
            dispatch(buildAction(GRADUATE_PROGRAM_MAP, { status: ACTION_STATUS.SUCCESS, data: graduateProgramMap }));
        } catch(error) {
            console.error("Get Graduate Program Map Error: ", error);
            dispatch(buildAction(GRADUATE_PROGRAM_MAP,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getGraduateContacts(filter = {}) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_CONTACTS, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getGraduateContacts, variables: { filter } });
            const graduateContacts = (response?.data?.getGraduateContacts?.graduateContacts ?? []);
            dispatch(buildAction(GRADUATE_CONTACTS, { status: ACTION_STATUS.SUCCESS, data: transformGraduateContacts(graduateContacts) }));
        } catch(error) {
            console.error("Get Graduate Contacts Error: ", error);
            dispatch(buildAction(GRADUATE_CONTACTS, { status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getGraduateContactsPending() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_CONTACTS_PENDING, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getGraduateContactsPending });
            const graduateContacts = (response?.data?.getGraduateContactsPending?.graduateContacts ?? []);
            dispatch(buildAction(GRADUATE_CONTACTS_PENDING, { status: ACTION_STATUS.SUCCESS, data: transformGraduateContacts(graduateContacts) }));
        } catch(error) {
            console.error("Get Pending Graduate Contacts Error: ", error);
            dispatch(buildAction(GRADUATE_CONTACTS_PENDING, { status: ACTION_STATUS.ERROR }));
        }
    };
}

export function addGraduateContact(emplid, academicPlan, affiliation, pendingActive, pendingViewOnly) {
    return async (dispatch) => (dispatch(submitGraduateContact(Mutations.addGraduateContact, {
        request: { academicPlan, affiliation, emplid, pendingActive, pendingViewOnly }
    })));
}

export function updateGraduateContact(emplid, academicPlan, affiliation, pendingActive, pendingViewOnly, active, viewOnly) {
    return async (dispatch) => (dispatch(submitGraduateContact(Mutations.updateGraduateContact, {
        request: { academicPlan, active, affiliation, emplid, pendingActive, pendingViewOnly, viewOnly }
    })));
}

function submitGraduateContact(query, variables) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_CONTACT_SUBMIT, { status: ACTION_STATUS.LOADING }));
            await API.graphql({ query, variables });
            dispatch(buildAction(GRADUATE_CONTACT_SUBMIT, { status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Submit Graduate Contact Error: ", error);
            if(error?.errors?.[0]?.errorType === "Unauthorized") {
                dispatch(buildAction(GRADUATE_CONTACT_SUBMIT, { status: ACTION_STATUS.UNAUTHORIZED }));
            } else if(error?.errors?.[0]?.errorType === "Forbidden") {
                dispatch(buildAction(GRADUATE_CONTACT_SUBMIT, { status: ACTION_STATUS.FORBIDDEN, message: error?.errors?.[0]?.message }));
            } else {
                dispatch(buildAction(GRADUATE_CONTACT_SUBMIT, { status: ACTION_STATUS.ERROR }));
            }
        }
    };
}

export function approveGraduateContact(wfId) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_CONTACT_APPROVE, { status: ACTION_STATUS.LOADING }));
            await API.graphql({ query: Mutations.approveGraduateContact, variables: { wfId }});
            dispatch(buildAction(GRADUATE_CONTACT_APPROVE, { status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Approve Graduate Contact Error: ", error);
            dispatch(buildAction(GRADUATE_CONTACT_APPROVE, { status: ACTION_STATUS.ERROR }));
        }
    };
}

export function denyGraduateContact(wfId) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_CONTACT_DENY, { status: ACTION_STATUS.LOADING }));
            await API.graphql({ query: Mutations.denyGraduateContact, variables: { wfId }});
            dispatch(buildAction(GRADUATE_CONTACT_DENY, { status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Deny Graduate Contact Error: ", error);
            dispatch(buildAction(GRADUATE_CONTACT_DENY, { status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getGraduateContactAffiliationComments(id, emplid, academicPlan, affiliation) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_CONTACT_AFFILIATION_COMMENTS, { id, status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getGraduateContactAffiliationComments, variables: { emplid, academicPlan, affiliation } });
            const comments = response.data.getGraduateContactAffiliationComments.comments;
            dispatch(buildAction(GRADUATE_CONTACT_AFFILIATION_COMMENTS, { data: comments, id, status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Get Graduate Contact Affiliation Comments Error: ", error);
            dispatch(buildAction(GRADUATE_CONTACT_AFFILIATION_COMMENTS, { id, status: ACTION_STATUS.ERROR }))
        }
    };
}

export function getGraduateContactAffiliationWorkflowHistory(id, emplid, academicPlan, affiliation) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY, { id, status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getGraduateContactAffiliationWorkflowHistory, variables: { emplid, academicPlan, affiliation } });
            const workflowHistory = response.data.getGraduateContactAffiliationWorkflowHistory.workflowHistory;
            dispatch(buildAction(GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY, { data: workflowHistory, id, status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Get Graduate Contact Affiliation Workflow History Error: ", error);
            dispatch(buildAction(GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY, { id, status: ACTION_STATUS.ERROR }))
        }
    };
}

export function createGraduateContactAffiliationComment(id, emplid, academicPlan, affiliation, text) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_CONTACT_AFFILIATION_COMMENT, { id, status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Mutations.createGraduateContactAffiliationComment, variables: { emplid, academicPlan, affiliation, text } });
            const comment = response.data.createGraduateContactAffiliationComment.comment;
            dispatch(buildAction(GRADUATE_CONTACT_AFFILIATION_COMMENT, { data: comment, id, status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Create Graduate Contact Affiliation Comment Error: ", error);
            if(error?.errors?.[0]?.errorType === "Forbidden") {
                dispatch(buildAction(GRADUATE_CONTACT_AFFILIATION_COMMENT, { id, message: error?.errors?.[0]?.message, status: ACTION_STATUS.FORBIDDEN }));
            } else {
                dispatch(buildAction(GRADUATE_CONTACT_AFFILIATION_COMMENT, { id, status: ACTION_STATUS.ERROR }));
            }
        }
    };
}

export function getGraduateFaculty(filter = {}) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_FACULTY, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getGraduateFaculty, variables: { filter } });
            const graduateFaculty = (response?.data?.getGraduateFaculty?.graduateFaculty ?? []);
            dispatch(buildAction(GRADUATE_FACULTY, { status: ACTION_STATUS.SUCCESS, data: transformGraduateFaculty(graduateFaculty) }));
        } catch(error) {
            console.error("Get Graduate Faculty Error: ", error);
            dispatch(buildAction(GRADUATE_FACULTY, { status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getGraduateFacultyPending() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_FACULTY_PENDING, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getGraduateFacultyPending });
            const graduateFaculty = (response?.data?.getGraduateFacultyPending?.graduateFaculty ?? []);
            dispatch(buildAction(GRADUATE_FACULTY_PENDING, { status: ACTION_STATUS.SUCCESS, data: transformGraduateFaculty(graduateFaculty) }));
        } catch(error) {
            console.error("Get Graduate Faculty Pending Error: ", error);
            dispatch(buildAction(GRADUATE_FACULTY_PENDING, { status: ACTION_STATUS.ERROR }));
        }
    };
}

export function addGraduateFaculty(emplid, program, pendingActive, pendingCategory, pendingFacultyType) {
    return async (dispatch) => (dispatch(submitGraduateFaculty(Mutations.addGraduateFaculty, {
        request: { emplid, program, pendingActive, pendingCategory, pendingFacultyType }
    })));
}

export function updateGraduateFaculty(emplid, program, pendingActive, pendingCategory, pendingFacultyType, active, category, facultyType) {
    return async (dispatch) => (dispatch(submitGraduateFaculty(Mutations.updateGraduateFaculty, {
        request: { active, category, emplid, facultyType, pendingActive, pendingCategory, pendingFacultyType, program }
    })));
}

function submitGraduateFaculty(query, variables) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_FACULTY_SUBMIT, { status: ACTION_STATUS.LOADING }));
            await API.graphql({ query, variables });
            dispatch(buildAction(GRADUATE_FACULTY_SUBMIT, { status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Submit Graduate Faculty Error: ", error);
            if(error?.errors?.[0]?.errorType === "Unauthorized") {
                dispatch(buildAction(GRADUATE_FACULTY_SUBMIT, { status: ACTION_STATUS.UNAUTHORIZED }));
            } else if(error?.errors?.[0]?.errorType === "Forbidden") {
                dispatch(buildAction(GRADUATE_FACULTY_SUBMIT, { status: ACTION_STATUS.FORBIDDEN, message: error?.errors?.[0]?.message }));
            } else {
                dispatch(buildAction(GRADUATE_FACULTY_SUBMIT, { status: ACTION_STATUS.ERROR }));
            }
        }
    };
}

export function approveGraduateFaculty(wfId) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_FACULTY_APPROVE, { status: ACTION_STATUS.LOADING }));
            await API.graphql({ query: Mutations.approveGraduateFaculty, variables: { wfId }});
            dispatch(buildAction(GRADUATE_FACULTY_APPROVE, { status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Approve Graduate Faculty Error: ", error);
            dispatch(buildAction(GRADUATE_FACULTY_APPROVE, { status: ACTION_STATUS.ERROR }));
        }
    };
}

export function denyGraduateFaculty(wfId) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_FACULTY_DENY, { status: ACTION_STATUS.LOADING }));
            await API.graphql({ query: Mutations.denyGraduateFaculty, variables: { wfId }});
            dispatch(buildAction(GRADUATE_FACULTY_DENY, { status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Deny Graduate Faculty Error: ", error);
            dispatch(buildAction(GRADUATE_FACULTY_DENY, { status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getGraduateFacultyAppointmentComments(id, emplid, program) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_COMMENTS, { id, status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getGraduateFacultyAppointmentComments, variables: { emplid, program } });
            const comments = response.data.getGraduateFacultyAppointmentComments.comments;
            dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_COMMENTS, { data: comments, id, status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Get Graduate Faculty Appointment Comments Error: ", error);
            dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_COMMENTS, { id, status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getGraduateFacultyAppointmentWorkflowHistory(id, emplid, program) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY, { id, status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getGraduateFacultyAppointmentWorkflowHistory, variables: { emplid, program } });
            const workflowHistory = response.data.getGraduateFacultyAppointmentWorkflowHistory.workflowHistory;
            dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY, { data: workflowHistory, id, status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Get Graduate Faculty Appointment Workflow History Error: ", error);
            dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY, { id, status: ACTION_STATUS.ERROR }));
        }
    };
}

export function createGraduateFacultyAppointmentComment(id, emplid, program, text) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_COMMENT, { id, status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Mutations.createGraduateFacultyAppointmentComment, variables: { emplid, program, text } });
            const comment = { ...response.data.createGraduateFacultyAppointmentComment.comment, emplid, program };
            dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_COMMENT, { data: comment, id, status: ACTION_STATUS.SUCCESS }));
        } catch(error) {
            console.error("Create Graduate Faculty Appointment Comment Error: ", error);
            if(error?.errors?.[0]?.errorType === "Forbidden") {
                dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_COMMENT, { id, message: error?.errors?.[0]?.message, status: ACTION_STATUS.FORBIDDEN }));
            } else {
                dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_COMMENT, { id, status: ACTION_STATUS.ERROR }));
            }
        }
    };
}

export function resetGraduateFacultyAppointmentComment(id) {
    return async (dispatch) => {
        dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_COMMENT_RESET, { id }));
    };
}

export function resetGraduateFacultyAppointmentComments(id) {
    return async (dispatch) => {
        dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_COMMENTS_RESET, { id }));
    };
}

export function resetGraduateFacultyAppointmentWorkflowHistory(id) {
    return async (dispatch) => {
        dispatch(buildAction(GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY_RESET, { id }));
    };
}