import { generateClient } from "aws-amplify/api";
import * as Queries from "../graphql/queries";
import { APPROVAL_STATUS } from "../util/constants";

const API = generateClient();

export async function pendingFormExists(filter = {}, includeNewStatus = false, excludeFormId = null) {
    const response = await API.graphql({ query: Queries.getForms, variables: { filter } });
    
    const errors = (response?.errors ?? []);
    if(errors.length > 0) throw new Error(errors[0].message);
    
    let forms = (response?.data?.getForms?.forms ?? []);
    if(excludeFormId) forms = forms.filter((form) => (form.id !== excludeFormId));
  
    const filterStatuses = [APPROVAL_STATUS.APPROVED, APPROVAL_STATUS.PENDING];
    if(includeNewStatus === true) filterStatuses.push(APPROVAL_STATUS.NEW);
    forms = forms.filter((form) => (filterStatuses.includes(form.status)));
    
    return forms.length > 0;
  }